.container {

    background-color: white;
    padding-bottom: 1rem;
    padding:2rem;
    font-family: "Mukta";
}

.hold {
    background-color: rgb(255, 255, 255);
  
   
}

.logo {
    width: 14rem;
    height: 7rem;
 
}

.logo2 {
    width: 14rem;
    height: 11rem;
}

Link{
    text-decoration: none;
}

.copy {
    color: #1f2a4b;
    width: 9rem;
    text-decoration: none;
    text-decoration: none;

}

.cont{
    border: 1px solid  #fb6007;
    background-color: #fb6007;
}


.li{
    color: #1f2a4b;
    list-style: none;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 0.9rem;
    font-weight: 200;
    font-family: "Mukta";
    text-decoration: none;
    text-underline-offset:unset;
    text-align: center;

}



Link{
    text-decoration: none;
}

.li:hover {
    color: #fb6007;
    cursor: pointer;
    text-transform: uppercase;
    text-decoration: none;  
      text-decoration: none;
}

.hold {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 1.5rem;
    
}

.extraHead {
    color: #1f2a4b;
    font-size: 1rem;
    font-weight: 500;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;

}

.extra {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    text-decoration: none;
    text-align: center;
    align-items: center;
    place-items: center;
    text-decoration: none;
 
}

.insta {
    width: 2.5rem;
    height: 2.5rem;
   margin: 0.3REM;

}

.reach {
    margin-top: 1rem;
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
 
}

.locHold {
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
    text-decoration: none;
  
}

.locTxt {

    color: #1f2a4b;
    width: auto;
    text-decoration: none;
    text-align: center;
    text-decoration: none;
    font-size: 0.9rm;
}


.line {
    color: #fb6007;
}

.company {
    color: #1f2a4b;
    text-align: center;
    font-size: 0.9rem;
}

.hrt {
    width: 0.9rem;
    height: 0.9rem;
    margin-left: 0.3rem;
    margin-right: 0.2rem;
}


.fr1,.fr2,.fr3,.map,.fr5{
    width:100%;
}

.map{
    height:14rem;
    width:15rem;
}




@media screen and (max-width:768px)
{

    .hold {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        gap: 1rem;
        
    }  
    
    .map{
        height:14rem;
        width:15rem;
        display:flex;
margin-left:1rem;
    }
    
}