.container {
    max-width: auto;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    margin-top:0rem;
    background-image: linear-gradient(rgba(0, 0, 0, 0.455),rgba(0, 0, 0, 0.437)),url(./../../Assets/wjk1.jpg);
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;

  
}

.left {
    flex: 1;
    text-align: center;
   margin-top: 1rem;
   margin-left: 1rem;
    background-color: rgba(255, 255, 255, 0.914);
    
}

.leftp{
   
    font-family: "Fira Sans";
    color:#1f2a4b;
    font-weight:500;
    font-size:1rem;
    text-align: justify;
    letter-spacing: 0.08rem;
   line-break: auto;
   margin-left:1rem;
   padding:2rem;



}





.right {
    flex: 1;
    text-align: center;

}


.ri {
 width: 60%;
    height: auto;
    display: block;
    margin: 0 auto;
}


.heading{

    font-family: "Londrina Outline";
    color:#fb6207;

    font-weight:bolder;
      text-align: center;
      letter-spacing: 0.2rem;
      font-size: 2rem;

      margin-left:1rem;
  
  }
  
  
  .heads{
  
    font-family: "Londrina Outline";
    color:#1f2a4b;

    font-weight:bolder;
      text-align: center;
      letter-spacing: 0.2rem;
      font-size: 2rem;
  
  }
@media screen and (max-width:768px)
{

    .container {
        max-width: 100vw;
      
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: auto;
        margin-top:1rem;
        background-image: linear-gradient(rgba(0, 0, 0, 0.455),rgba(0, 0, 0, 0.437)),url(./../../Assets/wjk1.jpg);
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-size: cover;
    
      
    }
    
    .left {
        
        text-align: center;
        background-color: rgba(255, 255, 255, 0.914);
        margin-left:-1rem;
        margin:1rem;
    }
    
    .leftp{
       
        font-family: "Fira Sans";
        color:#1f2a4b;
        font-weight:500;
        font-size:1rem;
        text-align: justify;
        letter-spacing: 0.08rem;
       line-break: auto;

    
    
    
    
    }
    
    
    
    
    
    .right {
        flex: 1;
        text-align: center;
    
    }
    
    
    .ri {
     width: 80%;
        height: auto;
        display: block;
        margin: 0 auto;
    }
    
    
    .heading{
    
        font-family: "Londrina Outline";
        color:#fb6207;
    
        font-weight:bolder;
          text-align: center;
          letter-spacing: 0.2rem;
          font-size: 2rem;
    
          margin-left:1rem;
      
      }
      
      
      .heads{
      
        font-family: "Londrina Outline";
        color:#1f2a4b;
    
        font-weight:bolder;
          text-align: center;
          letter-spacing: 0.2rem;
          font-size: 2rem;
      
      }

}