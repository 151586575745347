/*.form{
 
width:40%;
    box-shadow: 0px 0px 0PX 2px #00000052;
padding:2%;
border:1px solid black;
    border-radius: 5px;
    margin-top:8rem;
  }

  input[type="text"]
  {
    border:0.5px solid black;
    border-radius: 0.2REM;
    width:80%;
    font-weight: bolder;
color:#fb6207;
font-family: "Ubuntu"; 
font-size: 1rem;
  
  }

  input[type="email"]
  {
    border:0.5px solid black;
    border-radius: 0.2REM;
    width:150%;
    color:#fb6207;
    font-weight: bolder;
font-family: "Ubuntu"; 
font-size: 1rem;
  
  }

  textarea
  {
    border:0.5px solid black;
    border-radius: 0.2REM;
    width:100%;
    color:#fb6207;
    font-weight: bolder;
font-family: "Ubuntu"; 
font-size: 1rem;
  
  }

  select{
    border:0.5px solid black;
    border-radius: 0.2REM;
    width:80%;
    color:#fb6207;
font-family: "Ubuntu"; 
font-size: 1rem;

  }


  option::selection{
    color:green;
  }

  option:hover{
    background-color: yellow;
  }

  .row{
    display:flex;
justify-content: space-around;
PADDING:1REM;
  }


  .rowS{
   
     margin-left: 6rem;
      }
  .enq{
    display: flex;
    justify-content: center;
  }

  label{
    color:#1f2a4b;
    font-weight: bold;
    font-family: "Ubuntu"; 
  font-size: 1rem;
}

*/

.enq{
  display: flex;
  flex-direction: row;
  justify-content: space-around;

}
.form{
  display:flex;

flex-direction: column;
place-items: center;
  justify-content: space-around;
  font-family:"Poppins";

  width:50%;
overflow:hidden;
  background-color: white;

  }
  
  input{
    border: none;
    width:40%;
    border-bottom: 2px solid #999999;
    outline: none;
    background: transparent;
    font-size: 1rem;
    color: #fb6207;
  transition: 2s ease;
  font-family:"Poppins";
  text-transform: uppercase;
  }
  
  .inputfield{
    border: none;
    width:50%;
    border-bottom: 2px solid #999999;
    outline: none;
    background: transparent;
    font-size: 1rem;
    color: #fb6207;
  transition: 2s ease;
  font-family:"Poppins";
  text-transform: uppercase;
  }
  
  input::-webkit-file-upload-button{
    border: none;
    width: auto;
    border-bottom: 2px solid #999999;
    outline: none;
    background: transparent;
    font-size: 1px;
    color: #fb6207;
  transition: 2s ease;
  font-family:"Poppins";
  }
  
  
  input::-webkit-textfield-decoration-container{
    font-size: 1rem;
    color: #fb6207;
  transition: 2s ease;
  font-family:"Poppins";
  }
  
  #file{
    background:transparent;
  }
  
  select{
    border: none;
    border-bottom: 2px solid #999999;
    background: transparent;
    font-size: 1rem;
    color: #fb6207;
  transition: 2s ease;
    outline: none;
width:40%;
    font-family:"Poppins";
    }
  
    select:focus {
      border-image: linear-gradient(to right, #ef5a03, #d1d357);
      border-image-slice: 1;
    }
  
  
  input::placeholder{
  color: rgba(201, 3, 3, 0.424);
  text-align: center;
  font-family:"Poppins";
  }
  
  option{
    color: #fb6207;
    text-align: center;
    font-family:"Poppins";
    font-size: 0.8rem;
    width:40%;
    }
  
  label{
  
  font-size: 1rem;
  position: relative;
  color:#1f2a4b;
  font-family:"Poppins";
  }

  
  
  input:focus {
  border-image: linear-gradient(to right, #ef5a03, #d1d357);
  border-image-slice: 1;
  }
  
  .textarea
  {
    width:40%;
    height:5rem;
    font-size: 1rem;
    color: #fb6207;
  transition: 2s ease;
  font-family:"Poppins";
  text-transform: uppercase;
  }

  .textarea:focus {
    border-image: linear-gradient(to right, #ef5a03, #d1d357);
    border-image-slice: 1;
    }

  .file{
    width:40%;
  }
  
  .buttonqd {
  
  padding: 0.2rem;
  margin: 0px;
  width:6rem;
  height:3rem;
  background-color: rgb(203, 210, 251);
  border:1px solid black;
  color: rgb(0, 0, 0);
  font-size: 1rem;
  border: none;
  cursor: pointer;
  border-radius: 1.9rem;

  margin-top: 1.2rem;
  font-style: "Poppins";
  /*box-shadow: 0px 2px 4px 0px rgba(255, 0, 0, 0.701);*/
  }
  
  
  

  .heading{

    font-family: "Londrina Outline";
    color:#fb6207;
     
    font-weight:bolder;
      text-align: center;
      letter-spacing: 0.2rem;
      font-size: 2rem;
  
  }
  
  
  .heads{
  
    font-family: "Londrina Outline";
    color:#1f2a4b;
     
    font-weight:bolder;
      text-align: center;
      letter-spacing: 0.2rem;
      font-size: 2rem;
  
  }


.left {
    flex: 1;
    text-align: center;
    padding: 4rem;
}

.leftp{
   
    font-family: "Fira Sans";
    color:#fb6207;
    font-weight:800;
    font-size:0.9rem;
    text-align: center;
    letter-spacing: 0rem;
   line-break: auto;
text-decoration:none ;

}

.leftp1{
   
    font-family: "Fira Sans";
    color:#1f2a4b;
    font-weight:500;
    font-size:1rem;
    text-align: center;
    letter-spacing: 0.08rem;
   line-break: auto;
text-decoration:none ;

}
@media screen and (max-width:768px)
{
  .enq {
      max-width: auto;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: auto;
      margin-top:2rem;
  }

  .heading{

      font-family: "Londrina Outline";
      color:#fb6207;
       margin-top: 2rem;
      font-weight:bolder;
        text-align: center;
        letter-spacing: 0.2rem;
        font-size: 2rem;
    
    }

    .form{
      display:flex;
    
    flex-direction: column;
    place-items: center;
      justify-content: space-around;
      font-family:"Poppins";
    
      width:100%;
    
      background-color: white;
    
      }


      input{
        border: none;
        width:80%;
        border-bottom: 2px solid #999999;
        outline: none;
        background: transparent;
        font-size: 1rem;
        color: #fb6207;
      transition: 2s ease;
      font-family:"Poppins";
      text-transform: uppercase;
      }
      
      .inputfield{
        border: none;
        width:80%;
        border-bottom: 2px solid #999999;
        outline: none;
        background: transparent;
        font-size: 1rem;
        color: #fb6207;
      transition: 2s ease;
      font-family:"Poppins";
      text-transform: uppercase;
      }
      
      input::-webkit-file-upload-button{
        border: none;
        width: auto;
        border-bottom: 2px solid #999999;
        outline: none;
        background: transparent;
        font-size: 1px;
        color: #fb6207;
      transition: 2s ease;
      font-family:"Poppins";
      }
      
      
      input::-webkit-textfield-decoration-container{
        font-size: 1rem;
        color: #fb6207;
      transition: 2s ease;
      font-family:"Poppins";
      }
      
      #file{
        background:transparent;
      }
      
      select{
        border: none;
        border-bottom: 2px solid #999999;
        background: transparent;
        font-size: 1rem;
        color: #fb6207;
      transition: 2s ease;
        outline: none;
        width:80%;
        font-family:"Poppins";
        }
      
        select:focus {
          border-image: linear-gradient(to right, #ef5a03, #d1d357);
          border-image-slice: 1;
        }
      
      
      input::placeholder{
      color: rgba(201, 3, 3, 0.424);
      text-align: center;
      font-family:"Poppins";
      }
      
      option{
        color: #fb6207;
        background-color:white ;
        padding:0;
        margin:0;
        font-family:"Poppins";
        font-size: 0.4rem;
 
        }
      
      label{
      
      font-size: 1rem;
      position: relative;
      color:#1f2a4b;
      font-family:"Poppins";
      }
    
      
      
      input:focus {
      border-image: linear-gradient(to right, #ef5a03, #d1d357);
      border-image-slice: 1;
      }
      
      .textarea
      {
        width:80%;
        height:5rem;
      }
      .file{
        width:80%;
      }
     .formcontrol {
      width:120px;
      height:5rem;
      padding: 5px\9;
        border: none;
        overflow-y:hidden;
     }
 
      
}

