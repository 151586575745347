.contain {
padding:0.5rem;

    justify-content: center;
   
}

.contain:hover{
    transition: transform 1s;
     transform: scale(1.15);
}


.hold {

    display: flex;
    text-align: center;
    flex-direction: column;
    width: 11.5rem;
    height: 11.5rem;
    border: none;
    border-radius:0 1rem 1rem 1rem;
  box-shadow: 1px 1px 3px 1px rgb(215, 214, 214);

   /* background: linear-gradient(to bottom,#ff8037,#fb6007, #fb6007c4);*/
}

.imgHold {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    background-color: #bcf8ff6e;
   
}

.li{
    text-decoration: none;
}

.btn{
    background: none;
    border-radius: 2rem;
    border: 0.02rem solid black;
    padding:0.5rem;
    height:1.5rem;
    width:7rem;
    text-decoration: none;
}

.rdm{
    text-decoration: none;
    color:black;   
}

.img {
    width: 13rem;
    height: 10rem;
}



.headhold{

    text-align: center;
    display: flex;
    justify-content: center;
align-items: center;
    font-weight: 600;
    font-size: 1rem;
    font-family: "Fira Sans";
    color:black;
    height: 4.5rem;
    width: 14rem;
    border: 1px black solid;
    clip-path: polygon(30% 0%, 83% 0, 100% 100%, 85% 100%, 83% 81%, 81% 100%, 0 100%, 0 0);
   /* background-color: #ff8f4e;*/
   background-color: #fb6207;

}




@media screen and (max-width:768px)
{
    .hold {

        display: flex;
        flex-direction: column;
      margin-top:2rem;
      justify-content: center;
        width: 11.5rem;
        height: 11.5rem;
        border: none;
        border-radius:0 1rem 1rem 1rem;
      box-shadow: 1px 1px 3px 1px rgb(215, 214, 214);
    
       /* background: linear-gradient(to bottom,#ff8037,#fb6007, #fb6007c4);*/
    }
}