.AccHold {
 
    padding-top: 1rem;
    padding-bottom: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    background-color: none;
}

.left {
    gap: .7rem;
    width: 15rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.right {
    width: 41rem;
    height: 38rem;
    background-color: whitesmoke;
    border-radius: 2rem;
    border:none;
    padding: 2rem;
    justify-content: center;
    align-items: center;
    
}



.AccBtn {
    border: none;
    width: 11rem;
    height: 3.5rem;
    border-radius: 0.3rem;
    background-color: white;
    color: black;
    margin-bottom: 1rem;
    font-weight: bold;
    padding: 1rem;
    height: 4rem;
}

.AccBtn11 {
    border-radius: 0.3rem;
    background-color: white;
    padding: 1rem;
    height: 4rem;
}

.AccBtn1 {
    border-radius: 0.3rem;
    border: none;
    width: 11rem;
    height: 4rem;
    color: #fb6007;
    background-color: white;
    margin-bottom: 1rem;
    font-weight: bold;
    padding: 1rem;
}

.AccBtn:hover {
    background-color: rgb(172, 243, 255);
}

.accContainIn {
 
    justify-content: center;
    gap: 0.5rem;
padding:1rem;
}

.rightIcon {
    margin-top: 0rem;
    margin-right: 0.3rem;
    width: 1.2rem;
    height: 1.2rem;
    
}



.upimg{

display: flex;
flex-direction: row;

justify-content: space-around;
align-items: center;

    
}


.ih,.msh{

    font-family: "Ubuntu";
    color: #fb6007;
    text-transform: uppercase;
    font-weight: 600;
letter-spacing: 0.2rem;
}


.ip,.ms{

    font-family: "Ubuntu";
    
    text-transform: capitalize;
    font-weight: 600;
    color: #1f2a4b;

}

.fh{
    font-family: "Ubuntu";
    color: #fb6007;
    text-transform: uppercase;
    font-weight: 600;
letter-spacing: 0.2rem;
font-size:1rem;

}

.fp{
    font-family: "Ubuntu";
    color: #1f2a4b;
    text-transform: uppercase;
    font-weight: 600;
letter-spacing: 0.2rem;
font-size:0.8rem;

}



.li{
    list-style-type: none;
    font-family: "Ubuntu";
    color: #fb6007;
    font-size: 1rem;
}

.lib{
    list-style-type: none;
    font-family: "Ubuntu";
    color: #1f2a4b;
    font-size: 1rem;
  
}


.img{

    height: 15rem;
    width:15rem;
  }

  /*

@media screen and (max-width:768px) {
    .AccHold {
        margin-top: 4rem;
        padding-top: 4rem;
        padding-bottom: 4rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: .5rem;
        background-color: rgb(243, 241, 241);
        padding: 1rem;
        font-size: .6rem;
    }
    .left {
        gap: .5rem;
        width: 10rem;
        
        display: flex;
        flex-direction: column;
    }
    .AccBtn {
        border: none;
        width: 8rem;
        height: 3.5rem;
        border-radius: 0.3rem;
        background-color: white;
        color: black;
        margin-bottom: 1rem;
        font-weight: bold;
        padding: 1rem;
        height: 4rem;
        font-size: .6rem;
    }
    
    .AccBtn11 {
        border-radius: 0.3rem;
        background-color: rgb(8, 146, 171);
        padding: 1rem;
        height: 4rem;
        font-size: .6rem;
    }
    
    .AccBtn1 {
        border-radius: 0.3rem;
        border: none;
        width: 8rem;
        height: 4rem;
        color: white;
        background-color: rgb(8, 146, 171);
        margin-bottom: 1rem;
        font-weight: bold;
        padding: 1rem;
        font-size: .6rem;
        
    }
    .right {
        width: 41rem;
        height: 44rem;
        background-color: white;
        border-radius: 2rem;
        padding: 2rem;
        
    }
}*/



@media screen and (max-width:768px)
{

  .AccHold {

      place-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 1rem;
      background: white;
  margin-top: 2rem;;
   
    
  
  }


  .left {
      gap: .2rem;
      width: 15rem;
      margin-top:4rem;
      margin-left: 5.5rem;
      display: flex;
      flex-direction: column;
      
  }
  
  .right {
     width: 100vw;
     margin-left:0rem;
      background-color: white;
      border-radius: 2rem;
height:auto;
      border: 1px solid red;
     
  }


  
.SProduct1{
  display: flex;
 flex-direction: column;
 justify-content: space-around;
 padding:1rem;
 gap:1rem;
 
}


.SProduct11{
  display: flex;
 flex-direction: column;
 justify-content: space-around;
 padding:1rem;
 gap:3rem;
 
}

.PD
{
  font-family: "Poppins";
  padding-left:1rem;
  text-align: center;
}

.pd{
width:100%;
 padding: 0.5rem;
  text-align: justify;
  font-family: "Poppins";
  
  }

.Productmains{
 
  width: 100%;
  height: 8rem;
margin-left: -1.5rem;

}


.aji{

  width:100%;
  text-align: center;
  font-family: "Poppins";

}


.upimg{

    display: flex;
    flex-direction: column;
    gap:2rem;
    justify-content: space-around;
    align-items: center;
    
        
    }
    

}