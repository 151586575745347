
.contain {
  padding: 2rem;

}

.card{
background-color: white;
width: 300px;
display: relative; 
text-align: center;
margin-top: 100px;
box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.name{

font-family: "Fira Sans";
color:#1f2a4b;
font-weight:300;
font-size:0.8rem;
text-align: center;
letter-spacing: 0.08rem;
line-break: auto;
}

.review{
  margin: auto;
  padding-left: 2rem;
  padding-right: 2rem;
  box-sizing:border-box;
  border-style: none solid;
 
font-family: "Fira Sans";
color:#1f2a4b;
font-weight:300;
font-size:0.8rem;
text-align: center;
letter-spacing: 0.08rem;
line-break: auto;
}




.imgg{
height: 80px;
width:80px;
border-radius: 50%;
margin-top: 20px;
border: solid;
justify-content: center;
}


.stars{
  color:gold;
}