.hold{
    background-image: linear-gradient(rgba(0, 0, 0, 0.638),rgba(0, 0, 0, 0.704)),url(./../../Assets/wjk1.jpg);

margin-top: -0.9rem;
background-attachment: fixed;
background-repeat: no-repeat;
background-size: cover;
}

.section {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 1rem;
    margin:1rem;
    

}

.division {
    flex: 1;

    padding: 1rem;
    margin:1rem;
    text-align: center;
}

.leftps{
   
    font-family: "Fira Sans";
    color:white;
    font-weight:300;
    font-size:1rem;
    text-align: center;
    letter-spacing: 0.08rem;
   line-break: auto;

}

.prof{
   
  font-family: "Londrina Outline";
  /*color:#fb6207;*/
  color:whitesmoke;
font-size: 2.5rem;
  font-weight:bolder;
  letter-spacing: 0.2rem;
}


@media screen and (max-width:768px)
{

  .section {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 1rem;
    margin:1rem;
    

}
}