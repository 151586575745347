.container {
    max-width: auto;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: auto;
   
}


.containe{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.left {
    flex: 1;
    text-align: center;
    padding: 4rem;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.914);
}

.leftp{
   
    font-family: "Fira Sans";
    color:#1f2a4b;
    font-weight:300;
    font-size:1rem;
    text-align: justify;
    letter-spacing: 0.08rem;
   line-break: auto;


}

.right {
    flex: 1;
    text-align: center;
    padding: 20px;
    width: 100%;
}

.ri {
 width: 90%;
    height: auto;
    display: block;
    margin: 0 auto;
}


.heading{

    font-family: "Londrina Outline";
    color:#fb6207;

    font-weight:bolder;
      text-align: center;
      letter-spacing: 0.2rem;
      font-size: 2rem;
  
  }
  
  
  .heads{
  
    font-family: "Londrina Outline";
    color:#1f2a4b;

    font-weight:bolder;
      text-align: center;
      letter-spacing: 0.2rem;
      font-size: 2rem;
  
  }

  .rii {
    width:40rem;
    height:auto;
       height: auto;
       display: block;
       margin: 0 auto;
   }

   .leftpa{
    background-color: rgba(255, 255, 255, 0.914);
    font-family: "Fira Sans";
    color:#1f2a4b;
    font-weight:300;
    font-size:1rem;
    text-align: center;
    letter-spacing: 0.08rem;
   line-break: auto;
   padding-top: 0.5rem;



}

.rihi{
    width:30rem;
    height:20rem;
}

.middle{

    height:30rem;
    display:flex;
    float:right;
flex-direction: column;
justify-content: space-around;
place-items: center;
    width:30%;
   
}

.riii{
    width:60%;
}



.rd{
    width:20%;
    height:20%;
    margin-top: 2rem;
}

.hot{
    margin-top:0rem;
    background-image: linear-gradient(rgba(0, 0, 0, 0.455),rgba(0, 0, 0, 0.437)),url(./../../Assets/bg6.jpeg);
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
}


@media screen and (max-width:786px)
{
    .container {
        max-width: auto;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: auto;
 
    
        background-image: linear-gradient(rgba(0, 0, 0, 0.455),rgba(0, 0, 0, 0.437)),url(./../../Assets/bg6.jpeg);
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .riii{
        width:16rem;
    }

    .rd{
        padding:2rem;
        width:98vw;
    }
}