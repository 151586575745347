

.mm{
  height: 1.5rem;
  background-color:#1f2a4b;
display: flex;
justify-content: space-between;
  width:100%;
  margin-top: 0.5rem;
}

.email{color:white}

.me,.mn{
  text-decoration: none;
  color:whitesmoke;
 
}


.mob{
 margin-right:2rem;
  color:white;
  float:right;
  display: inline-flex;
  justify-content: flex-end;
  justify-content: space-around;

}
























.nvb{
 
  position:fixed;
  top: -0.5rem;
  left:0;

  background-color: white;

  width:100%;

 z-index: 1;
}



.img{
  height:6rem;
  width:11rem;
  position: relative;


}

.immg{
  height:3.5rem;
  width:6rem;
  position: relative;
left:50%

}



.li {
   
  padding:11%;
  height: 100%;
  display: flex;
  color: #1f2a4b;
  list-style: none;
  justify-content: center;
  align-items: center;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1.2rem;
margin-left:0.3rem;
  font-weight: 200;
  font-family: "Mukta";
  text-decoration: none;
}

.lii{
  color: #1f2a4b;
  list-style: none;
  text-decoration: none;
  font-family: "Ubuntu";
}
.cont{
  border: 1px solid  #fb6007;
  background-color: #fb6007;
}


.li:hover {
  color: #fb6007;
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none;
}


.metid{
justify-content:space-between;
position:sticky;

}

.meti{
float:right;
margin-left:5rem;
}


@media screen and (max-width:768px) {


.ss{
  color:white;
  font-size: 1rem;
  margin-left: -12.5rem;
  
}
.img {
  width: 12rem;
  height: 12rem;
  margin-left: 18.5rem;
}
.tgl{
  color:white;
  background-color:antiquewhite;
}


}