.row{
    display:flex;
    flex-direction: row;
    justify-content: center;
 
}

.head{

  font-family: "Londrina Outline";
  color:#fb6207;

  font-weight:bolder;
    text-align: center;
    letter-spacing: 0.2rem;
    font-size: 2rem;

}


.heads{

  font-family: "Londrina Outline";
  color:#1f2a4b;

  font-weight:bolder;
    text-align: center;
    letter-spacing: 0.2rem;
    font-size: 2rem;

}

@media screen and (max-width:768px){
  
  .row{
    display:flex;
    flex-direction: column;
    justify-content: center;
    margin-left:3rem;
 padding:1rem;
    place-items: center;
 
}

}