.hold{
    
background-image: linear-gradient(rgba(0, 0, 0, 0.455),rgba(0, 0, 0, 0.437)),url(./../../Assets/wjk1.jpg);
background-attachment: fixed;
background-repeat: no-repeat;
background-size: cover;
}
.container {
    max-width: auto;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    margin-top:-2rem;
}

.left,.right {
    flex: 1;
    text-align: center;
    padding: 4rem;
    background-color: rgba(255, 255, 255, 0.914);
    border-left: 1px double black;
    border-right: 1px double black;
    margin:1rem;
    height:17rem
}

.leftp{
   
    font-family: "Fira Sans";
    color:#1f2a4b;
    font-weight:300;
    font-size:1rem;
    text-align: center;
    letter-spacing: 0.08rem;
   line-break: auto;

}

.heading{

    font-family: "Londrina Outline";
    color:#fb6207;
    
    font-weight:bolder;
      text-align: center;
      letter-spacing: 0.2rem;
      font-size: 2rem;
  
  }
  
  
  .heads{
  
    font-family: "Londrina Outline";
    color:#1f2a4b;
   
    font-weight:bolder;
      text-align: center;
      letter-spacing: 0.2rem;
      font-size: 2rem;
  
  }


  @media screen and (max-width:768px)
  {
    .container {
        max-width: auto;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: auto;
        margin-top:-2rem;
    }
  }