.qa{
    height:auto;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    place-items: center;
    padding:1rem;
    background-image: linear-gradient(rgba(0, 0, 0, 0.455),rgba(0, 0, 0, 0.437)),url(./../../Assets/bg6.jpeg);
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
}



.aq{
 
    justify-content: center;
    width: 85%;
}

.p{
   
       
    font-family: "Fira Sans";
    color:#1f2a4b;
    font-weight:300;
    font-size:1rem;
    text-align: center;
    letter-spacing: 0.08rem;
   line-break: auto;
   padding:2% 7%; 

}



.head{

    font-family: "Londrina Outline";
    color:#fb6207;
    font-weight:bolder;
      text-align: center;
      letter-spacing: 0.2rem;
      font-size: 2rem;
      border-color: aliceblue;
      background-color: rgba(255, 255, 255, 0.914);
  
  }
  
  
  .heads{
  
    font-family: "Londrina Outline";
    color:#1f2a4b;
    font-weight:bolder;
      text-align: center;
      letter-spacing: 0.2rem;
      font-size: 2rem;
  
  }


  .row{
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    padding:1rem;
  }


  .col{
    display:flex;
    flex-direction: column;
justify-content: center;
place-items: center;
padding:0.3rem;
width: 30%;
height:18rem;
background-color: rgba(255, 255, 255, 0.914);


}

.col:hover{
  transition: transform 1s;
   transform: scale(1.08);
}


.mg{
    width: 92%;
    height:13rem;

}

  

@media screen and (max-width:768px)
{

  
  .row{
    display:flex;
    flex-direction: column;
    justify-content: space-around;
    padding:1rem;
  }

  .col{
    display:flex;
    flex-direction: column;
justify-content: center;
place-items: center;

width: 75vw;
height:18rem;
background-color: rgba(255, 255, 255, 0.914);
margin: 1rem;

}
.mg{
  width: 92%;
  height:13rem;

}


.aq{
 
  justify-content: center;
  width: 100%;
}
}