/* Rsli.module.css */


  
  /* Add any additional styling you need for your Rcard component or other elements as necessary */
  

  .rsl{
    margin-top: 1rem;
    background-image: linear-gradient(rgba(0, 0, 0, 0.287),rgba(0, 0, 0, 0.45)),url(./../../Assets/wjk5.jpg);
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
  }

@media screen and (max-width:768px) {
 

 
}