.contain {
    padding: 0.1rem;
   margin:0.5rem;
    justify-content: center;
}

.hold {

    display: flex;
    text-align: center;
    flex-direction: column;
    width: 11rem;
    height: 12rem;
    border: none;
    border-radius:0 1rem 1rem 0rem;
  
  box-shadow: 1px 1px 3px 1px rgb(215, 214, 214);

   /* background: linear-gradient(to bottom,#ff8037,#fb6007, #fb6007c4);*/
}

.hold:hover{
    transition: transform 1.5s;
     transform: scale(1.10);
     background-color: #ffe5d6b7;
}


.hold:hover.img{
 background-color: white;
}


.imgHold {
    display: flex;
    justify-content: center;


   
}

.btn{
    background: none;
    border-radius: 2rem;
    border: 0.02rem solid black;
    padding:0.5rem;
    height:1.5rem;
    width:7rem;
    text-decoration: none;
}

.rdm{
    text-decoration: none;
    color:black;   
}

.img {
    width: 6rem;
    height:6rem;
    border-spacing: 3em 2em;
    border-radius: 40%;
    border:1px solid #fb6007;
    background-color: white;
  
}



.headhold{

    padding:0.2rem;
    text-align: center;
    font-weight: 600;
    font-size: 0.7rem;
    height: 1rem;
    font-family: "Fira Sans";
    color:#1f2a4b;
}



 .triangle {

   left:0;
   bottom:0;
   width: 2rem;
   height:2rem;
   position:relative;

   box-shadow: inset 0 0 80px #fb6007;
   clip-path: polygon(0 0, 0% 100%, 74% 100%);

}


.head1{
    font-weight: 600;
    font-size: 0.6rem;
    font-family: "Fira Sans";
    color:#1f2a4b;


}


.hold:hover.head1{
    background-color: white;
    display: block;
   }


@media screen and (max-width:768px) {
 
 
}