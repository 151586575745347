.container {
    max-width: auto;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;

}

.hold{
    
background-image: linear-gradient(rgba(0, 0, 0, 0.455),rgba(0, 0, 0, 0.437)),url(./../../Assets/wjk1.jpg);
background-attachment: fixed;
background-repeat: no-repeat;
background-size: cover;
}

.right {
    flex: 1;
    text-align: center;
    padding: 20px;
}

.ri {
 width:30rem;
 height:30rem;
    height: auto;
    display: block;
    margin: 0 auto;
}


.heading{

    font-family: "Londrina Outline";
    color:#fb6207;
    background-color: rgba(255, 255, 255, 0.914);
    font-weight:bolder;
      text-align: center;
      letter-spacing: 0.2rem;
      font-size: 2rem;
  
  }
  
  
  .heads{
  
    font-family: "Londrina Outline";
    color:#1f2a4b;
   
    font-weight:bolder;
      text-align: center;
      letter-spacing: 0.2rem;
      font-size: 2rem;
  
  }


  @media screen and (max-width:768px)
  {
    .ri {
        width:25rem;
        height:30rem;
        padding:2rem;
           display: block;
      
       }
    
    }

  