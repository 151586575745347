.wlc{
    padding:1rem;
    display: flex;
    justify-content: space-around;
    position: relative;
}

.wl,.wr{
    width: 100%;
    position: relative;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
   
}

.head{

    font-family: "Londrina Outline";
    color:#fb6207;
  
    font-weight:bolder;
      text-align: center;
      letter-spacing: 0.2rem;
      font-size: 2rem;
  
  }
  
  
  .heads{
  
    font-family: "Londrina Outline";
    color:#1f2a4b;
  
    font-weight:bolder;
      text-align: center;
      letter-spacing: 0.2rem;
      font-size: 2rem;
  
  }


  .row{

display:flex;
justify-content: space-around;
padding:1rem;

  }

  .b1{

    border:1px solid rgb(195, 195, 195);
    border-radius:1em;
    width: 100%;
    position: relative;
 margin-left:0.8rem;
 margin-left:0.8rem;
 margin-bottom:0.8rem;
 height:8rem;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    

  }


  .im{

    width: auto;
    position: relative;


  }

    .im2{
    width: 100%;
    position: relative;
 
    display: flex;
    flex-direction: column;
    justify-content: center;
   
}

.img{
    padding:0.5rem;
    height:4rem;
    width:4rem;
  
}


.bh1
{
    text-align: center;
   
    font-size: 1.2rem;
}

.bh2{
    text-align: center;
    padding: 0.2em;
    font-size: 0.8rem;
}


.i{

  height:25rem;
  width:35rem;

  display: flex;
  justify-content: center;
}





@media screen and (max-width:768px)
{

  .wlc{
    padding:1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: relative;
}

.wl,.wr{
  width: 100%;
  position: relative;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
 
}
.i{

  height:25rem;
  width:35rem;

  display: none;
  flex-direction: column;
 
}

.row{

  display:flex;
  flex-direction: column;
  justify-content: center;
  padding:1rem;
  
    }
  
    .b1{
  
      border:1px solid rgb(195, 195, 195);
      border-radius:1em;
      width: 100%;
      position: relative;

   margin-bottom:0.8rem;
   height:12rem;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      
  
    }

    .im{

      width: auto;
      position: relative;
  display:flex;
  justify-content: center;
  margin:1rem;

  
    }
}