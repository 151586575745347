.qa{
    height:20rem;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    place-items: center;
    background-image: linear-gradient(rgba(0, 0, 0, 0),rgba(0, 0, 0, 0)),url(./../../Assets/wjk.jpg);
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
}



.aq{
 
    justify-content: center;

    border-style: double;
    border-color: aliceblue;
    background-color: rgba(255, 255, 255, 0.914);
 
    width: 80%;
}

.p{
   
       
    font-family: "Fira Sans";
    color:#1f2a4b;
    font-weight:300;
    font-size:1rem;
    text-align: center;
    letter-spacing: 0.08rem;
   line-break: auto;
   padding:2% 7%; 

}

.head{

    font-family: "Londrina Outline";
    color:#fb6207;
  
    font-weight:bolder;
      text-align: center;
      letter-spacing: 0.2rem;
      font-size: 2rem;
  
  }
  
  
  .heads{
  
    font-family: "Londrina Outline";
    color:#1f2a4b;
  
    font-weight:bolder;
      text-align: center;
      letter-spacing: 0.2rem;
      font-size: 2rem;
  
  }


  @media screen and (max-width:768px)
  {

    .qa{
        height:auto;
        padding-top:2rem;
        padding-bottom:2rem;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        place-items: center;
        background-image: linear-gradient(rgba(0, 0, 0, 0),rgba(0, 0, 0, 0)),url(./../../Assets/wjk.jpg);
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-size: cover;
    }
    
  }