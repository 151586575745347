
.mm{
    height: 1.5rem;
    background-color:#1f2a4b;
  display: flex;
  justify-content: space-between;
    width:100%;

  }
  
  .email{color:white}
  
  .me,.mn{
    text-decoration: none;
    color:whitesmoke;
   
  }
  
  
  .mob{
   margin-right:2rem;
    color:white;
    float:right;
    display: inline-flex;
    justify-content: flex-end;
    justify-content: space-around;

  }
  


.nvb{
    height:6.5rem; 
    width:100%;
    background-color:white;
 z-index: 2;
 position: fixed;
}



.navHold {
    display: flex;
    flex-direction: column;
    height: 6.5rem;
    width: 100%;
    background-color:white;



}

.navbarItems {
    display: flex;
    flex-direction: row;
    font-family: 'Poppins', sans-serif;
    
    font-size: 1rem;
    font-weight: 500;
    padding: 2rem;
    text-decoration: none;
    /*background-color: rgb(225, 223, 223);*/
  color:black;

    /*box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);*/
    width: 100%;
   height:6.5rem;
    /*border-radius: 13px;*/

    top: 0;
   
 background-color:white;
    z-index: 2;

}


.linker{
color: #1f2a4b;
font-family: "Mukta";
font-weight: 200;
text-transform: uppercase;
font-size: 1.2rem;
}

.list {
    color: black;
    list-style: none;
    text-decoration: none;
    color: chartreuse;
    width: 100%
}

.list:hover {
    transform: scale(1.2);
    transition: 0.5s;
    color: chartreuse;
    /* background-color: rgb(3, 88, 224);*/
    border-radius: 5px;
    list-style: none;
}



.comlogo2 {
    width: 12rem;
    height: 6rem;
    margin-top: -1.5rem;
}

.comlogo3 {
    padding-top: 0.5rem;
    padding-left: 2rem;
    width: 10rem;
    height: 4rem;
}

.nav-menu {
 
  display: flex;
  flex-direction: row;
  text-decoration: none;
  gap:2rem;
    list-style: none;
    align-items: center;
    text-align: center;
    justify-content: end;
    margin-top: 0.7rem;
    padding-left: 3rem;
    color: black; 
}

.nav-links {
    text-decoration: none;
    color: black; 

    white-space: nowrap;
}



.fa-bars,
.fa-times {
    /*color: #fff;*/
    color: black;


}

.menu-icons {
    display: none;
}

.nav-links-mobile {
    padding: 0.5rem 1rem;
    white-space: nowrap;
  
    border-radius: 3px;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 500;
    color: #222;
}

.dropdown {
    position: relative;
    display: inline-block;
    /*color: #f1f1f1;*/
    color: black;
}

.dropdownContent {
    margin-top: 1rem;

}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;

    min-width: 21rem;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;

    border-radius: 0.5rem;
    font-size: 0.9rem;
    text-align: left;

}

.dropdown-content a:hover {

    color:#fb6007;
}

.dropdown:hover .dropdown-content {
    display: block;
}




















.dropdown2 {
    position: relative;
    display: inline-block;
    /*color: #f1f1f1;*/
    
}

.dropdown-content2 {
    display: none;
    position: absolute;
    background-color: #f9f9f9;

    min-width: 10rem;

    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;

    border-radius: 0.5rem;
    font-size: 0.8rem;
    text-align: left;

}

.dropdown-content2 a:hover {
color:#fb6007;
}

.dropdown2:hover .dropdown-content2 {

    display: block;
}










.dropdown3 {
    position: relative;
    display: inline-block;
    /*color: #f1f1f1;*/
    
}

.dropdown-content3 {
    display: none;
    position: absolute;
    background-color: #f9f9f9;

    min-width: 10rem;

    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;

    border-radius: 0.5rem;
    font-size: 0.8rem;
    text-align: left;

}

.dropdown-content3 a:hover {
color:#fb6007;
}

.dropdown3:hover .dropdown-content3 {

    display: block;
}









.linker {
    text-decoration: none;
}

a {
    color: black;
}



.li {
   
    padding:11%;
    height: 100%;
    display: flex;
    color: #1f2a4b;
    list-style: none;
    justify-content: center;
    align-items: center;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1.2rem;
  margin-left:0.3rem;
    font-weight: 200;
    font-family: "Mukta";
    text-decoration: none;
  }
  
  .lii{
    color: #1f2a4b;
    list-style: none;
    text-decoration: none;
    font-family: "Poppins";
    line-height: 0.1rem;
  }
  .cont{
    border: 1px solid  #fb6007;
    background-color: #fb6007;
  }
  
  
  .lii:hover {
    color: #fb6007;
    cursor: pointer;
    text-transform: uppercase;
    text-decoration: none;
  }

@media screen and (max-width:768px) {


    .mm{
        height: 1.5rem;
        background-color:#1f2a4b;
      display: flex;
      justify-content: space-between;
        width:100%;
        font-size: 0.5rem;

    
      }
      
      .email{color:white;  font-size: 0.5rem;}
      
      .me,.mn{
        text-decoration: none;
        color:whitesmoke;
        font-size: 0.5rem;
      }
      
      
      .mob{
       margin-right:2rem;
        color:white;
        float:right;
        display: inline-flex;
        justify-content: flex-end;
        justify-content: space-around;
       
    
      }
      









      .nvb{
        width:100%;
        height:10rem;   
        background-color:white;
     z-index: 2;


    }
    
    
    
    .navHold {
        display: flex;
        flex-direction: column;
        height: 7.5rem;
        width: 100%;
        background-color:white;
    
        position: fixed;
      
    
    
    }

    .navbarItems {
        z-index: 2;
        width:100%;
        gap:1rem
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background-color: rgba(255, 255, 255, .25);
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
        backdrop-filter: blur(4px);
        border-radius: 13px;
        width: 100%;
        height: auto;
        position: absolute;
        top: 4rem;
        left: -110%;
        align-items: stretch;
    
        margin: 0;
        z-index: 2;
        transition: all 0.3s ease-in-out;
    }

    .nav-menu.active {
        left: 0%;
    }

    .nav-links {
        display: block;
        width: 100%;
        padding: 2rem 0;
        color: #222;
        background-color: rgba(255, 255, 255, .25);
    }

    .nav-links:hover {

        background: rgba(255, 255, 255, .25);
        backdrop-filter: blur(20px);
    }

    .menu-icons {
        display: block;
    }

    .nav-links-mobile {
        padding: 1rem;
        display: block;
        text-align: center;
        font-size: 1.3rem;
        font-weight: bold;
        width: 80%;
        margin: auto;
    }




    .comlogo2 {
        width: 10rem;
        height: 6rem;
        margin-top: -1.5rem;
    }
    
    .comlogo3 {
  padding-left: -4rem;
        width: 8rem;
        height: 3rem;
    }
    

    .dropdown2:hover .dropdown-content2 {

        display: block;
        margin-top: -8rem;
    }
    


}