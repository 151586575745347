.h{
    
    font-family: "Londrina Outline";
    color:#fb6207;
 
    font-weight:bolder;
    font-size:2.1rem;
    letter-spacing: 0.2rem;
    text-align: center;
    padding:auto;
}

.hh{
    
    font-family: "Londrina Outline";
    color:#1f2a4b;
 
    font-weight:bolder;
    font-size:2.1rem;
    letter-spacing: 0.2rem;
    text-align: center;
    padding:auto;
}


.p{
   
    
    font-family: "Fira Sans";
    color:#1f2a4b;
    font-weight:300;
    font-size:1rem;
    text-align: center;
    letter-spacing: 0.08rem;
   line-break: auto;
   padding:2% 7%; 

}
.commit{
    padding:3rem;
    
}