
.contain {
    padding: 2rem;
}


.hold {

    display: flex;
    text-align: center;
    flex-direction: column;

    border: none;
    border-radius:0 0 2rem 0;
    box-shadow: 1px 1px 10px 1px rgb(171, 170, 170);

}
.imgHold {
    display: flex;
    justify-content: center;
align-items: center;
    background:#ffb589;
    width: auto;
    height: auto;
    padding:1rem;
}


.img {
    width: 11rem;
    height: 11rem;

   
}


