.head{

    font-family: "Londrina Outline";
    color:#fb6207;
    background-color: rgba(255, 255, 255, 0.914);
    font-weight:bolder;
      text-align: center;
     
      letter-spacing: 0.2rem;
      font-size: 2rem;
      margin:3rem;
      
  }
  .hold{  
    margin-top:0rem;  
    background-image: linear-gradient(rgba(0, 0, 0, 0.455),rgba(0, 0, 0, 0.437)),url(./../../Assets/wjk1.jpg);
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;}
  
  .heads{
  
    font-family: "Londrina Outline";
    color:#1f2a4b;

    font-weight:bolder;
      text-align: center;
      letter-spacing: 0.2rem;
      font-size: 2rem;
  
  }

  .box{
    padding: 2rem;
  
  }


  .row{
    display:flex;
    flex-direction: row;
    justify-content: center;
  }

  .jj{
    margin:1rem;
    padding:0.5rem;
    font-family: "Fira Sans";
    justify-content: space-around;
    border:1.5px solid #fb6207;
    outline: 0.5px solid #1f2a4b;
    outline-offset: -5px;
    text-align: center;
    font-size: 1.2rem;
text-transform: capitalize;
background-color: rgba(255, 255, 255, 0.914);
  
}


@media screen and (max-width:768px)
{

  .row{
    display:flex;
    flex-direction: column;
    justify-content: center;
  }
  
}