.Pro1{
    background: linear-gradient(to right, #fb6007da 22%, white 20%, white 20%);
    height:auto;
    width:100vw;
    top:0;
    }
    
    .Prw{
        padding: 6rem;
        /*display:flex;
        flex-direction: row;*/
        display: inline-grid;
        grid-template-columns: 1fr 1.7fr;
        justify-content: space-around;
        
        }
  
    .l1{
    
    /*border:1px solid black;*/
    
    
    
    transform: translateY(30%);
    
    /*background-color: whitesmoke;*/
    }
    
    .r1{
   
    
    /*border:1px solid black;*/
     
       /*background-color: whitesmoke;*/
    }
    
    .pcc{
    
        text-align: left;
        font-family: "Ubuntu";
        
    }
    
    .hr1{
        background-color:#fb6007da;
        border-color:#fb6007da;
        height:0.rem;
    }
    
    .hr{
      background-color:#fb6007da;
      border-color:#fb6007da;
    border-style: solid;
    }
    
    
    .head{
    
        font-family: "Londrina Outline";
        color:#fb6207;
     
        font-weight:bolder;
       
          letter-spacing: 0.2rem;
          font-size: 2rem;
    
      
      }
      
      
      .heads{
      
        font-family: "Londrina Outline";
        color:#1f2a4b;
    
        font-weight:bolder;
     
          letter-spacing: 0.2rem;
          font-size: 2rem;
      
      }
    
    
    
      .head1{
    
        font-family: "Ubuntu";
        color:#fb6207;
      
        font-weight:bolder;
        letter-spacing: 0.2rem;
          font-size: 1rem;
          transform: translateX(0rem);
      
      }
      
      
      .heads1{
      
        font-family: "Ubuntu";
        color:#1f2a4b;
     
        font-weight:bolder;
        letter-spacing: 0.2rem;
          font-size: 1rem;
      
      }
    
    
      .wrapper {
        display: flex;
        flex-direction: row;
      justify-content: space-between;
width: 117%;
     padding:0.8rem;
font-family: "Ubuntu";
color:#1f2a4b;
font-size: 0.9rem;
     transform: translateX(-5rem);
      -webkit-box-shadow: 2px 2px 3px -1px rgba(0,0,0,0.35);
    
      }
      

.wra1{
width:100%;
padding:1rem;
border-right:1px solid #1f2a4b;

}

.wra2{
    width:100%;
    padding:1rem;
    border-right:1px solid #1f2a4b;
}

.wra3{
    width:100%;
 padding:1rem;
}



      .p2
      {
        font-family: "Ubuntu";
        color:#1f2a4b;
        
       
    }

    .hhd{
      font-weight: bolder;  
    }
    
.bbx{
    text-decoration: none;
   font-family: "Ubuntu";
    color:#1f2a4b;
  }
  
    .imm{
  background:#9aa7ce;
  border:3px groove #fb6207;
  border-radius:2rem;
  height:15rem;
  width:20rem
    }
    
     
    .keywords thead tr th { 
        font-weight: bold;
      

      }



  
      
    @media screen and (max-width:768px)
    {
      .Pro1{
        background:none;
        height:auto;
        width:100vw;
        top:0;


        }
        
        .Prw{
            padding: 0rem;
            /*display:flex;
            flex-direction: row;*/
            display: block;
     margin-top: 2rem;
            justify-content: space-around;
            
            }
      
        .l1{
        
        /*border:1px solid black;*/
        
        transform: translateX(2rem);
 
        
        /*background-color: whitesmoke;*/
        }
        
        .r1{
       
        
        /*border:1px solid black;*/
   
           /*background-color: whitesmoke;*/
        }
        
        .pcc{
        
            text-align: center;
            font-family: "Ubuntu";
            
        }
        
        .hr1{
            background-color:#fb6007da;
            border-color:#fb6007da;
            height:0rem;
        }
        
        .hr{
          background-color:#fb6007da;
          border-color:#fb6007da;
        border-style: solid;
        }
        
        
        .head{
        
            font-family: "Londrina Outline";
            color:#fb6207;
         
            font-weight:bolder;
           
              letter-spacing: 0.2rem;
              font-size: 2rem;
        
          
          }
          
          
          .heads{
          
            font-family: "Londrina Outline";
            color:#1f2a4b;
        
            font-weight:bolder;
         
              letter-spacing: 0.2rem;
              font-size: 2rem;
          
          }
        
        
        
          .head1{
        
            font-family: "Ubuntu";
            color:#fb6207;
        
            font-weight:bolder;
         text-align: center;
            letter-spacing: 0.2rem;
              font-size: 1rem;
        
              transform: translateX(0rem);
          }
          
          
          .heads1{
          
            font-family: "Ubuntu";
            color:#1f2a4b;
         
            font-weight:bolder;
            letter-spacing: 0.2rem;
              font-size: 1rem;
          
          }
        
        
          .wrapper {
            display: flex;
            flex-direction: column;
          justify-content: space-between;
    width: 100%;
         padding:0.8rem;
    font-family: "Ubuntu";
    color:#1f2a4b;
    font-size: 0.9rem;
         transform: translateX(0rem);
      
        
          }
          
    
    .wra1{
    width:100%;

border:none;
border-bottom:1px solid #fb6207;
    
    }
    
    .wra2{
        width:100%;
        border:none;
        border-bottom:1px solid #fb6207;
    }
    
    .wra3{
        width:100%;
        border:none;
        border-bottom:1px solid #fb6207;
    }
    
          
    
          .p2
          {
            font-family: "Ubuntu";
            color:#1f2a4b;
            
        }
    
        
    .bbx{
        text-decoration: none;
       font-family: "Ubuntu";
        color:#1f2a4b;
      }
      
        .imm{
      background:#9aa7ce;
      border:3px groove #fb6207;
      border-radius:2rem;
      height:15rem;
      width:20rem
        }
        
         
    
        
        
    }
    