.head{

  font-family: "Londrina Outline";
  color:#fb6207;
  font-weight:bolder;
    text-align: center;
    letter-spacing: 0.2rem;
    font-size: 2rem;

}


.heads{

  font-family: "Londrina Outline";
  color:#1f2a4b;

  font-weight:bolder;
    text-align: center;
    letter-spacing: 0.2rem;
    font-size: 2rem;

}

.bb{
  
  background:#fb6007;
}

