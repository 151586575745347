
:root{
  --perspective: 1000px;
  --link-color:#010790;
}

* {
	box-sizing: border-box;
}

.Hold{
  background-image: linear-gradient(rgba(0, 0, 0, 0.287),rgba(0, 0, 0, 0.45)),url(./../../Assets/wjk1.jpg);
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}



.h15{

  font-size: 1.5em;
  background-color: rgba(255, 255, 255, 0.914);
  width:20rem;
  text-align: center;
}

.book {
  display: block;
  position: relative;
  width: 20rem;
	height:25rem;
  margin:0 auto;
	margin-top: 2rem;
}

.gloss{
  position: absolute;
  top:0;
	z-index:200;
  overflow: hidden;
  width:20%;
  height:100%;
  opacity:0.5;
  background: linear-gradient(90deg, rgba(255,255,255,0), rgba(255,255,255,0.2), rgba(255,255,255,0));
  transition:all .5s;
  transform: translateX(-50%) rotateY(0deg);
  transform-origin: left center;
}

.cover {
	position: absolute;
    width:100%;
    height:100%;
	border: solid 1px grey;
    border-style: inset;
 justify-content: center;
	z-index:100;
  transition:transform .5s;
  transform: translateX(0);
  transform-origin: left center;
  backface-visibility: hidden;
  background: white;
  padding:1rem;
  font-size: 0.7rem;
}

.img{
    justify-content: center;   
    width:100%;
    padding-top: 1rem;;
}

.description{
  position: absolute;
  left:0;
  top:0;
  width:inherit;
  height:inherit;
	border: solid 1px grey;
	background:white;
	transition: all 1s;
	padding: 10% 10%;
  padding-top:5%;
  z-index:1;
}

.description .h1{
  font-family: 'Fira Sans';
	font-size: 1.1rem;
  text-align: center;

}

.book:hover{
		cursor: pointer;
}

.book:hover .cover {
    transform: perspective(var(--perspective)) rotateY(-80deg);
}

.book:hover .gloss {
    transform: perspective(var(--perspective)) rotateY(-80deg) translateX(100%) scaleX(5);
}

.book:hover .description {
    transform: translateX(20%);
}



.ul{
  color:#1f2a4b;
    list-style-type: square;
    font-size: 1rem;
    text-align: justify;
    padding:-1rem;
}

.h11{
  color:#1f2a4b;
  font-size: 1rem;
}

.kes{
font-family: "Londrina Outline";
color:#fb6207;

font-weight: bolder;
text-align: center;
letter-spacing: 0.2rem;
font-size: 2rem;

 width:15rem;
}

.nil{
    font-family: "Londrina Outline";
    color:#1f2a4b;
  
    text-align: center;
    letter-spacing: 0.2rem;
    font-size: 2rem;
    font-weight: bolder;
}

.ke{
    font-family: "Londrina Outline";
    color:#fb6207;
    font-style:italic;
    font-weight: bolder;
    text-align: center;
   
    }

    .ni{
        font-family: "Archivo";
        color:#1f2a4b;
        font-style:italic;
        font-weight: bolder;
        text-align: center;
        font-size: 1rem;
    }


    @media screen and (max-width:768px)
    {
      .book {
        display: block;
        position: relative;
        width: 15rem;
        height:23rem;
        margin:0 auto;
        margin-top: 2rem;
      }

      .description .h1{
        font-family: 'Fira Sans';
        font-size: 0.9rem;
        text-align: center;
      
      }

      


.ul{
  color:#1f2a4b;
    list-style-type: square;
    font-size: 0.85rem;
    text-align: left;
    padding:-1rem;
 
}

.h11{
  color:#1f2a4b;
  font-size: 0.9rem;
}

.kes{
font-family: "Londrina Outline";
color:#fb6207;

font-weight: bolder;
text-align: center;
letter-spacing: 0.2rem;
font-size: 1.5rem;

 width:15rem;
}

.nil{
    font-family: "Londrina Outline";
    color:#1f2a4b;
  
    text-align: center;
    letter-spacing: 0.2rem;
    font-size: 2rem;
    font-weight: bolder;
}

.ke{
    font-family: "Londrina Outline";
    color:#fb6207;
    font-style:italic;
    font-weight: bolder;
    text-align: center;
   
    }

    .ni{
        font-family: "Archivo";
        color:#1f2a4b;
        font-style:italic;
        font-weight: bolder;
        text-align: center;
        font-size: 1rem;
    }
      
    }