.container {
    max-width: auto;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    margin-top:2rem;
}

.left {
    flex: 1;
    text-align: center;
    padding: 4rem;
}

.leftp{
   
    font-family: "Fira Sans";
    color:#fb6207;
    font-weight:800;
    font-size:0.9rem;
    text-align: center;
    letter-spacing: 0rem;
   line-break: auto;
text-decoration:none ;

}

.leftp1{
   
    font-family: "Fira Sans";
    color:#1f2a4b;
    font-weight:500;
    font-size:1rem;
    text-align: center;
    letter-spacing: 0.08rem;
   line-break: auto;
text-decoration:none ;

}




.right {
    flex: 1;
    text-align: center;
    padding: 20px;
}

.ri {
 width: 70%;
    height: auto;
    display: block;
    margin: 0 auto;
}


.heading{

    font-family: "Londrina Outline";
    color:#fb6207;
     
    font-weight:bolder;
      text-align: center;
      letter-spacing: 0.2rem;
      font-size: 2rem;
  
  }
  
  
  .heads{
  
    font-family: "Londrina Outline";
    color:#1f2a4b;
     
    font-weight:bolder;
      text-align: center;
      letter-spacing: 0.2rem;
      font-size: 2rem;
  
  }

  @media screen and (max-width:768px)
  {
    .container {
        max-width: auto;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: auto;
        margin-top:2rem;
    }

    .heading{

        font-family: "Londrina Outline";
        color:#fb6207;
         margin-top: 2rem;
        font-weight:bolder;
          text-align: center;
          letter-spacing: 0.2rem;
          font-size: 2rem;
      
      }
  }
  