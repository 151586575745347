.heddd{
    position: relative;

padding:4rem;
display: flex;
flex-direction: row;
background-image: linear-gradient(rgba(0, 0, 0, 0.638),rgba(0, 0, 0, 0.704)),url(./../../Assets/wjk.jpg);
background-attachment: fixed;
background-repeat: no-repeat;
background-size: cover;


}

.head{

    font-family: "Londrina Outline";
    color:#fb6207;

    font-weight:bolder;
      text-align: center;
      letter-spacing: 0.1rem;
      font-size: 3rem;
  
  }
  
  
  .heads{
  
    font-family: "Londrina Outline";
    /*color:#1f2a4b;*/
    color:whitesmoke;

    font-weight:bolder;
      text-align: center;
      letter-spacing: 0.1rem;
      font-size: 3rem;
  
  }
  
  .h1{
    
    border-left:0.5rem solid #fb6207;
    padding:0.6rem;
  }

  .p1{

   color:white;
   font-family: "Fira Sans";

    font-weight:300;
    text-transform: capitalize;
      text-align: left;

      font-size: 1rem;
width:30rem;
  }

  .b1{

    border: none;
    width:AUTO;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.2rem;
    margin: 4px 2px;
    cursor: pointer;
    color:#1f2a4b;
    background-color: #fb6007;
 

  }

  .be{
    color:#1f2a4b;
    text-decoration: none;
  }


  .be:hover{
    color:whitesmoke;
  }


.d{
    display:flex;
    flex-direction:row;
 

}

.d2{

    padding-left:2rem;
}

.left1{
  justify-content: left;
  padding-left:3rem;
 width:50%;
 


}

.right1{
   justify-content: left;
   padding-left:8rem;
  width:50%;

}


.i1{

    height:3rem;
    width:3rem;
}

.h5
{
  font-family: "Fira Sans";
    font-size: 1rem;
    color:white;
   
}

.p5
{
  font-family: "Fira Sans";
    font-size: 0.8rem;
    color:whitesmoke;
}

.hd{
  font-family: "Fira Sans";
  justify-content: center;
    color:#1f2a4b;
    color:whitesmoke
}


@media screen and (max-width:768px)
{

  .heddd{
    position: relative;
width:100vw;
padding:0.5rem;
display: flex;
flex-direction: column;
background-image: linear-gradient(rgba(0, 0, 0, 0.638),rgba(0, 0, 0, 0.704)),url(./../../Assets/wjk.jpg);
background-attachment: fixed;
background-repeat: no-repeat;
background-size: cover;


}

.p1{

  color:white;
  font-family: "Fira Sans";
  font-weight:300;
  text-transform: capitalize;
  text-align: justify;
  font-size: 1rem;
width:75vw;
 }

 .b1{

  border: none;

width:11rem;
  text-align: left;
  text-decoration: none;
  display: inline-block;
  font-size: 1.2rem;
margin:1rem;
  cursor: pointer;
  color:#1f2a4b;
  background-color: #fb6007;


}

.right1{
  justify-content: left;
  margin-left: -4rem;
 width:100vw;

}



.d{
  display:flex;

  flex-direction:column;


}
.hd{
  font-family: "Fira Sans";
  justify-content: center;
    color:#1f2a4b;
    color:whitesmoke
}


.i1{
display: flex;
flex-direction: row;
margin-left: 2rem;
margin-top:1rem;
  height:3rem;
  width:3rem;
}

}