.slider{
    overflow-x: hidden;
width:100%;
z-index: -1;
   
}

.sli{
 height:70vh;
    width:100vw;
}


@media screen and (max-width:768px)
{
.slider{
    height:50vh;
    width:100vw;
}
    .sli{
        
        height:50vh;
        width:100vw;
    }  

    
}